import React, { useState } from 'react';
import { Button, Input, Typography, Box, Alert } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = () => {
        fetch('https://socket.mertcanureten.com.tr/api/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password })
        })
        .then((res) => res.json())
        .then((data) => {
            if (data.token) {
                // JWT Token'ı ve kullanıcı rolünü localStorage'a kaydediyoruz
                localStorage.setItem('token', data.token);
                const decoded = JSON.parse(atob(data.token.split('.')[1]));
                localStorage.setItem('role', decoded.role);
                // Başarılı giriş sonrası ana sayfaya yönlendiriliyor
                var navigate_link = '/';
                if(decoded.role === "result")
                    navigate_link = '/votes';
                navigate(navigate_link);
            } else {
                setError('Giriş başarısız.');
            }
        })
        .catch(() => setError('Sunucu hatası.'));
    };

    return (
        <Box sx={{ maxWidth: 400, mx: 'auto', mt: 10, p: 3, boxShadow: 'lg', borderRadius: 'md', textAlign: 'center' }}>
            <Typography level="h1" fontSize="lg" mb={2}>
                Giriş Yap
            </Typography>

            <Input 
                placeholder="Kullanıcı Adı" 
                value={username} 
                onChange={(e) => setUsername(e.target.value)} 
                sx={{ mb: 2 }}
            />

            <Input
                placeholder="Şifre"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ mb: 2 }}
            />

            {error && (
                <Alert color="danger" variant="soft" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}

            <Button onClick={handleLogin} sx={{ width: '100%', mb: 1 }}>
                Giriş Yap
            </Button>

            <Typography level="body2" sx={{ mt: 2 }}>
                Eğer bir hesabınız yoksa sistem yöneticisine başvurun.
            </Typography>
        </Box>
    );
};

export default LoginPage;
