import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, Typography, CircularProgress } from '@mui/joy';

const BallotBoxSelection = () => {
    const [ballotBoxes, setBallotBoxes] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); // Yüklenme durumu

    useEffect(() => {
        fetch('https://socket.mertcanureten.com.tr/api/ballot-boxes')
            .then((res) => res.json())
            .then((data) => {
                setBallotBoxes(data);
                setLoading(false);
            }
        );
    }, []);

    return (
        <Box sx={{ maxWidth: '900px', mx: 'auto', mt: 4, p: 2 }}>
            <Typography level="h1" fontSize="xl" textAlign="center" mb={3}>
                Sandık Seçimi
            </Typography>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (

            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: 2 }}>
                {ballotBoxes.map((box) => (
                    <Card
                        key={box.id}
                        variant="outlined"
                        onClick={() => navigate(`/entry/${box.id}`)}
                        sx={{ p: 3, cursor: 'pointer', '&:hover': { backgroundColor: 'primary.softBg' } }}
                    >
                        <Typography level="h2" fontSize="lg">
                            {box.name}
                        </Typography>
                    </Card>
                ))}
            </Box>
            )}
        </Box>
    );
};

export default BallotBoxSelection;
