import React, { useState, useEffect } from 'react';
import { Box, Card, Typography, Grid, CircularProgress } from '@mui/joy';
import io from 'socket.io-client';

const socket = io.connect('https://socket.mertcanureten.com.tr');

const VoteDisplay = () => {
    const [totalVotes, setTotalVotes] = useState(0);
    const [candidateVotes, setCandidateVotes] = useState({});
    const [votes, setVotes] = useState([]);
    const [candidates, setCandidates] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Aday bilgilerini al
        fetch('https://socket.mertcanureten.com.tr/api/candidates')
            .then((res) => res.json())
            .then((data) => {
                setCandidates(data);
            });
    
        // Toplam oy ve aday oylarını al
        fetch('https://socket.mertcanureten.com.tr/api/election-results')
            .then((res) => res.json())
            .then((data) => {
                setTotalVotes(data.totalVotes);
                setCandidateVotes(data.candidateVotes);
                setLoading(false);
            });
    
        // Sandık bazlı sonuçları al
        fetch('https://socket.mertcanureten.com.tr/api/ballot-box-results')
            .then((res) => res.json())
            .then((data) => {
                setVotes(data);
            });
    
        // Socket.IO ile anlık güncellemeleri dinle
        socket.on('voteAdded', (updatedData) => {
            setVotes((prevVotes) => ({
                ...prevVotes,
                [updatedData.ballot_box_id]: {
                    ...prevVotes[updatedData.ballot_box_id],
                    [updatedData.candidate_id]:
                        parseInt(prevVotes[updatedData.ballot_box_id]?.[updatedData.candidate_id] || 0) + parseInt(updatedData.vote)
                }
            }));
    
            // Toplam oy sayısını güncelle
            setTotalVotes((prevTotal) => parseInt(prevTotal) + parseInt(updatedData.vote));
            
            // Adayın toplam oy sayısını güncelle
            setCandidateVotes((prevVotes) => ({
                ...prevVotes,
                [updatedData.candidate_id]: 
                    (parseInt(prevVotes[updatedData.candidate_id] || 0) + parseInt(updatedData.vote))
            }));
        });
    
        return () => {
            socket.off('voteAdded');
        };
    }, []);
    
    return (
        <Box sx={{ maxWidth: '1200px', mx: 'auto', mt: 4, p: 2 }}>
            <Typography level="h1" fontSize="lg" textAlign="center" mb={3}>
                Seçim Sonuçları
            </Typography>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Typography level="h2" fontSize="lg" textAlign="center" mb={3}>
                        Toplam Oy Sayısı: {totalVotes}
                    </Typography>

                    <Typography level="h2" fontSize="lg" textAlign="center" mb={3}>
                        Sandık Bazlı Seçim Sonuçları
                    </Typography>

                    <Grid container spacing={2}>
                        {Object.keys(votes).map((ballotBoxId) => (
                            <Grid item xs={12} sm={6} md={4} key={ballotBoxId}>
                                <Card variant="outlined" sx={{ p: 2 }}>
                                    <Typography level="h3" fontSize="lg" textAlign="center" mb={1}>
                                        {ballotBoxId} Nolu Sandık
                                    </Typography>
                                    {candidates.map((candidate) => {
                                        const voteCount = votes[ballotBoxId]?.[candidate.id] || 0;
                                        return (
                                            <Typography key={candidate.id} level="body1">
                                                {candidate.name}: {voteCount}
                                            </Typography>
                                        );
                                    })}
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                    <Typography level="h2" fontSize="lg" textAlign="center" mt={4}>
                        Adayların Toplam Aldığı Oy
                    </Typography>

                    <Grid container spacing={2} mt={2}>
                        {candidates.map((candidate) => {
                            const candidateTotalVotes = candidateVotes[candidate.id] || 0;
                            return (
                                <Grid item xs={12} sm={6} md={4} key={candidate.id}>
                                    <Card variant="outlined" sx={{ p: 2 }}>
                                        <Typography level="body1">
                                            {candidate.name}: {candidateTotalVotes}
                                        </Typography>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                </>
            )}
        </Box>
    );
};

export default VoteDisplay;
