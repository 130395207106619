import React, { useState, useEffect } from 'react';
import { Checkbox, Input, Card, Typography, Box, IconButton, CircularProgress } from '@mui/joy';
import { FaPhoneAlt } from 'react-icons/fa';
import io from 'socket.io-client';

const socket = io.connect('https://socket.mertcanureten.com.tr');

const DelegateDisplay = () => {
    const [delegates, setDelegates] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch('https://socket.mertcanureten.com.tr/api/delegates')
            .then((res) => res.json())
            .then((data) => {
                setDelegates(data);
                setLoading(false);
            });

        socket.on('delegateVoted', (updatedDelegate) => {
            setDelegates((prevDelegates) =>
                prevDelegates.map((delegate) =>
                    delegate.id === updatedDelegate.id
                        ? { ...delegate, has_voted: updatedDelegate.has_voted }
                        : delegate
                )
            );
        });

        return () => {
            socket.off('delegateVoted');
        };
    }, []);

    const handleSearch = (e) => {
        setLoading(true);
        setSearchTerm(e.target.value);
        fetch(`https://socket.mertcanureten.com.tr/api/delegates/search?q=${e.target.value}`)
            .then((res) => res.json())
            .then((data) => {
                setDelegates(data);
                setLoading(false);
            });
    };

    const toggleVoteStatus = (delegateId, currentStatus) => {
        const newVoteStatus = !currentStatus;

        fetch(`https://socket.mertcanureten.com.tr/api/delegates/${delegateId}/vote`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ has_voted: newVoteStatus }),
        }).then((res) => {
            if (!res.ok) {
                console.error('Oy verme durumu güncellenirken bir hata oluştu');
            } else {
                setDelegates((prevDelegates) =>
                    prevDelegates.map((delegate) =>
                        delegate.id === delegateId
                            ? { ...delegate, has_voted: newVoteStatus }
                            : delegate
                    )
                );
            }
        });
    };

    return (
        <Box sx={{ maxWidth: '800px', mx: 'auto', mt: 4, p: 2 }}>
            <Typography level="h1" fontSize="xl" textAlign="center" mb={3}>
                İl Delegeleri
            </Typography>
            <Input
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Delege arayın..."
                fullWidth
                sx={{ mb: 2 }}
            />
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: 2 }}>
                {delegates.map((delegate) => (
                    <Card key={delegate.id} variant="outlined" sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                            <Typography level="h2" fontSize="lg">
                                {delegate.first_name} {delegate.last_name}
                            </Typography>
                            <Typography fontSize="md">TCKN: {delegate.tckn}</Typography>
                            <Typography fontSize="md">Doğum Yılı: {delegate.birth_year}</Typography>
                            <Typography fontSize="md">İlçe: {delegate.district}</Typography>
                            <Typography fontSize="md" mb={1}>{delegate.ballot_box_id} Nolu Sandık</Typography>
                        </Box>
                        
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', mt: 2 }}>
                            <IconButton 
                                aria-label="Telefonu ara"
                                onClick={() => window.open(`tel:${delegate.phone}`, '_self')}
                                variant="solid"
                                color="primary"
                                size="sm" // Butonu küçültüyoruz
                                sx={{ alignSelf: 'flex-start' }} // Sol alta hizalıyoruz
                            >
                                <FaPhoneAlt />
                            </IconButton>

                            <Checkbox
                                label="Oy Verdi mi?"
                                size="lg"
                                checked={delegate.has_voted}
                                onChange={() => toggleVoteStatus(delegate.id, delegate.has_voted)}
                                sx={{
                                    '--Checkbox-size': '2rem', // Checkbox boyutunu artırıyoruz
                                    '--Checkbox-gap': '1rem',  // Metin ile checkbox arasındaki boşluk
                                    alignSelf: 'flex-end' // Sağ alta hizalıyoruz
                                }}
                            />
                        </Box>
                    </Card>
                ))}
            </Box>
            )}
        </Box>
    );
};

export default DelegateDisplay;
