import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, BottomNavigation, BottomNavigationAction, Button } from '@mui/material';
import { Home, Assessment, Group } from '@mui/icons-material';

const Navbar = () => {
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate();
    
    // Kullanıcının rolünü localStorage'dan al
    const role = localStorage.getItem('role');

    const handleLogout = () => {
        // Token'ı localStorage'dan kaldır
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        // Kullanıcıyı giriş sayfasına yönlendir
        navigate('/login');
    };

    return (
        <Box sx={{ position: 'fixed', bottom: 0, width: '100%', bgcolor: 'background.paper' }}>
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                {role === 'admin' && (
                    <>
                        <BottomNavigationAction component={Link} to="/" label="Sandık Seçimi" icon={<Home />} />
                        <BottomNavigationAction component={Link} to="/votes" label="Oy Durumu" icon={<Assessment />} />
                        <BottomNavigationAction component={Link} to="/delegates" label="Delegeler" icon={<Group />} />
                    </>
                )}

                {role === 'data' && (
                    <>
                        <BottomNavigationAction component={Link} to="/" label="Sandık Seçimi" icon={<Home />} />
                        <BottomNavigationAction component={Link} to="/delegates" label="Delegeler" icon={<Group />} />
                    </>
                )}

                {role === 'result' && (
                    <>
                        <BottomNavigationAction component={Link} to="/votes" label="Oy Durumu" icon={<Assessment />} />
                        <BottomNavigationAction component={Link} to="/delegates" label="Delegeler" icon={<Group />} />
                    </>
                )}
            </BottomNavigation>

            {role && (
            <Button onClick={handleLogout} variant="outlined" sx={{ position: 'absolute', right: 16, top: -40 }}>
                Çıkış Yap
            </Button>
            )}
        </Box>
    );
};

export default Navbar;
