import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Typography, CircularProgress, Card } from '@mui/joy';
import io from 'socket.io-client';

const socket = io.connect('https://socket.mertcanureten.com.tr');

const VoteEntry = () => {
    const { id } = useParams(); // Sandık ID'si
    const [votes, setVotes] = useState({}); // Oy verileri
    const [candidates, setCandidates] = useState([]); // Adaylar
    const [loading, setLoading] = useState(true); // Yükleniyor durumu

    useEffect(() => {
        // Adayları almak için API isteği
        fetch(`https://socket.mertcanureten.com.tr/api/candidates`)
            .then((res) => res.json())
            .then((data) => {
                setCandidates(data);
                setLoading(false);
            });

        // Oy verilerini almak için API isteği
        fetch(`https://socket.mertcanureten.com.tr/api/votes/${id}`)
            .then((res) => res.json())
            .then((data) => {
                setVotes(data);
            });

        socket.on('votesUpdated', (updatedData) => {
            if (updatedData.id === parseInt(id)) {
                setVotes(updatedData);
            }
        });

        return () => {
            socket.off('votesUpdated');
        };
    }, [id]);

    const updateVotes = (candidateId, change) => {
        // Adayın mevcut oy sayısını al
        const currentVoteCount = parseInt(votes[candidateId]) || 0;
    
        // Yeni oy sayısını hesapla
        const newVoteCount = Math.max(0, currentVoteCount + change);
    
        // Oy sayısını güncelle
        const newVotes = {
            ...votes,
            [candidateId]: newVoteCount,
        };
    
        setVotes(newVotes); // Yeni oy sayısını state'e ata
    
        // Backend'e gönderilecek veri
        const voteData = {
            ballot_box_id: id, // Sandık ID'si
            candidate_id: candidateId, // Aday ID'si
            vote: change, // Oy sayısını artırmak/azaltmak için kullanılan değeri gönder
        };
    
        console.log(voteData); // Yeni oy verilerini konsola yazdır
    
        // Oy verilerini backend'e gönder
        fetch(`https://socket.mertcanureten.com.tr/api/votes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(voteData), // Gerekli verileri gönder
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.text();
        })
        .then((data) => {
            console.log(data); // Başarı mesajı
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };
            

    return (
        <Box sx={{ maxWidth: '600px', mx: 'auto', mt: 4, p: 2 }}>
            <Typography level="h1" fontSize="lg" textAlign="center" mb={3}>
                Sandık {id} - Oy Girişi
            </Typography>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                {candidates.map((candidate) => (
                    <Card key={candidate.id} variant="outlined" sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <Button
                            color="danger"
                            onClick={() => updateVotes(candidate.id, -1)}
                            disabled={votes[candidate.id] === 0 || votes[candidate.id] === "0"}
                            sx={{ width: '60px', height: '60px', fontSize: '24px' }}
                        >
                            -
                        </Button>
                        <Box sx={{ textAlign: 'center', flex: 1, mx: 2 }}>
                            <Typography level="h2" fontSize="lg">{candidate.name}</Typography>
                            <Typography level="h3" fontSize="2xl">{votes[candidate.id] || 0}</Typography>
                        </Box>
                        <Button
                            color="success"
                            onClick={() => updateVotes(candidate.id, 1)}
                            sx={{ width: '60px', height: '60px', fontSize: '24px' }}
                        >
                            +
                        </Button>
                        </Box>
                    </Card>
                ))}
                </Box>
            )}
        </Box>
    );
};

export default VoteEntry;
