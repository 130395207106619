import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { Container } from '@mui/system';

import BallotBoxSelection from './pages/BallotBoxSelection';
import VoteEntry from './pages/VoteEntry';
import VoteDisplay from './pages/VoteDisplay';
import DelegateDisplay from './pages/DelegateDisplay';
import Navbar from './components/Navbar';
import LoginPage from './pages/Login';
import ProtectedRoute from './ProtectedRoute';

const App = () => {
    return (
        <Router>
            <CssBaseline />
            <Container maxWidth="lg" sx={{ paddingBottom: '64px' }}>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/" element={<ProtectedRoute roles={['admin','data']}><BallotBoxSelection /></ProtectedRoute>} />
                    <Route path="/entry/:id" element={<ProtectedRoute roles={['admin','data']}><VoteEntry /></ProtectedRoute>} />
                    <Route path="/votes" element={<ProtectedRoute roles={['admin','result']}><VoteDisplay /></ProtectedRoute>} />
                    <Route path="/delegates" element={<ProtectedRoute roles={['admin','data','result']}><DelegateDisplay /></ProtectedRoute>} />
                </Routes>
            </Container>
            <Navbar />
        </Router>
    );
};

export default App;
